import axios from 'axios';
import Constants from 'src/constants';
import { backendUrl } from 'src/app-globals';

export const registration = {
    namespaced: true,
    state: {
        linkActiveId: {},
        externalId: '',
        countryCodes: {},
    },
    getters: {
        getLinkActiveId (state) {
            return state.linkActiveId;
        },
        getExternalId (state) {
            return state.externalId;
        },
        getCountryCodes (state) {
            return state.countryCodes;
        },
    },
    mutations: {
        setLinkActiveId (state, data) {
            state.linkActiveId = data;
        },
        setExternalId (state, id) {
            state.externalId = id;
        },
        clearOffLinkActiveId (state) {
            state.linkActiveId = {};
        },
        clearOffExternalId (state) {
            state.externalId = '';
        },
        setCountryCodes (state, data) {
            state.countryCodes = data;
        },
    },
    actions: {
       async createLinkActiveId ({ dispatch, commit }, payload) {
            const url = `${backendUrl}/api/user-update/check`;
            try {
                const response = await axios.post(url, payload);
                commit('setLinkActiveId', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in processing your registration.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async registerNewUser ({ dispatch }, payload) {
            const url = `${backendUrl}/api/user-update/register`;
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in registering the user.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        storeExternalId (context, id) {
            context.commit('setExternalId', id);
        },
        clearLinkActiveId ({ commit }) {
            commit('clearOffLinkActiveId');
        },
        clearExternalId ({ commit }) {
            commit('clearOffExternalId');
        },
        async fetchCountryCodes ({ dispatch, commit }) {
            const url = `${backendUrl}/api/country-codes`;
            try {
                const details = await axios.get(url);
                commit('setCountryCodes', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting country zip code.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
